import React from 'react';
import LichKabinetHeader from '../components/LichKabinet/LichKabinetHeader';
import MyprofileComponent from '../components/LichKabinet/MyprofileComponent';
import ReferalLink from '../components/LichKabinet/ReferalLink';
import ReferalProgramComponent from '../components/LichKabinet/referalprogram/ReferalProgramComponent';

function ReferalProgram() {
    return (
        <div className='lich-kabinet'>
            <LichKabinetHeader />
            <ReferalLink />
            <div style={{ display: "flex", gap: "20px",overflow:"hidden" }}>
                <MyprofileComponent />
                <ReferalProgramComponent />

            </div>
        </div>
    );
}

export default ReferalProgram;
