import React from 'react';
import LichKabinetHeader from '../components/LichKabinet/LichKabinetHeader';
import MyprofileComponent from '../components/LichKabinet/MyprofileComponent';
import SettingsForm from '../components/LichKabinet/settings/SettingsForm';
import ReferalLink from '../components/LichKabinet/ReferalLink';



function Settings() {
    return (
        <div className='lich-kabinet'>
            <LichKabinetHeader />
            <ReferalLink />
            <div style={{ display: "flex", gap: "20px" }}>
                <MyprofileComponent />
                <SettingsForm />
            </div>


        </div>
    );
}

export default Settings;
