import { Link } from "react-router-dom";

import subs from '../../../images/LichKabinet/transaction/subscribe.png'
import service from '../../../images/LichKabinet/transaction/services.png'
import user from '../../../images/LichKabinet/transaction/user.png'

const LastTransactions = () => {
    return (
        <main className="last-transaction-component">
            <section className="top-statistic-account">
                <h1 className='h1-statistic-account'>Last Transaction</h1>
                <Link to='/lk/mytransactions'>See All</Link>
            </section>

            <section className="section-last-transactions">

                <div className="transaction-div">
                    <img src={subs} />
                    <section className="transaction-info">
                        <h2>Spotify Subscription</h2>
                        <p>25 Jan 2021</p>
                    </section>
                    <section className="transaction-discription">
                        <p>Shopping</p>
                        <p>1234 ****</p>
                        <p>#12548796</p>
                        <p>Pending</p>
                        <span className="transaction-minus">-$150</span>
                    </section>
                </div>

                <div className="transaction-div">
                    <img src={service} />
                    <section className="transaction-info">
                        <h2>Mobile Service</h2>
                        <p>25 Jan 2021</p>
                    </section>
                    <section className="transaction-discription">
                        <p>Shopping</p>
                        <p>1234 ****</p>
                        <p>#12548796</p>
                        <p>Complete</p>
                        <span className="transaction-minus">-$340</span>
                    </section>
                </div>
                <div className="transaction-div">
                    <img src={user} />
                    <section className="transaction-info">
                        <h2>Emilly Wilson</h2>
                        <p>25 Jan 2021</p>
                    </section>
                    <section className="transaction-discription">
                        <p>Shopping</p>
                        <p>1234 ****</p>
                        <p>#12548796</p>
                        <p>Complete</p>
                        <span className="transaction-plus">+780</span>
                    </section>
                </div>
            </section>

        </main>
    );
};

export default LastTransactions;
