// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/BebasNeue-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../fonts/DarkerGrotesque-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../fonts/NotoSansTelugu_Condensed-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../fonts/NotoSansTelugu_Condensed-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../fonts/NotoSansTelugu_Condensed-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../fonts/NotoSansTelugu_Condensed-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../../fonts/Inter_24pt-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../../fonts/Inter_24pt-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../../fonts/Inter_24pt-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("../../fonts/Inter_24pt-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("../../fonts/RobotoCondensed-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@font-face {
    font-family: "BNR";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
    font-family: "DG";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
    font-family: "NR";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
    font-family: "NL";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
@font-face {
    font-family: "NT";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

@font-face {
    font-family: "NSB";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}

@font-face {
    font-family: "ISB";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}

@font-face {
    font-family: "IB";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}

@font-face {
    font-family: "IR";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_8___});
}

@font-face {
    font-family: "II";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_9___});
}

@font-face {
    font-family: "RR";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_10___});
}
`, "",{"version":3,"sources":["webpack://./src/pages/css/Fonts.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,4CAA6C;AACjD;;AAEA;IACI,iBAAiB;IACjB,4CAAmD;AACvD;;AAEA;IACI,iBAAiB;IACjB,4CAA4D;AAChE;;AAEA;IACI,iBAAiB;IACjB,4CAA0D;AAC9D;AACA;IACI,iBAAiB;IACjB,4CAAyD;AAC7D;;AAEA;IACI,kBAAkB;IAClB,4CAA6D;AACjE;;AAEA;IACI,kBAAkB;IAClB,4CAA+C;AACnD;;AAEA;IACI,iBAAiB;IACjB,4CAA2C;AAC/C;;AAEA;IACI,iBAAiB;IACjB,4CAA8C;AAClD;;AAEA;IACI,iBAAiB;IACjB,4CAA6C;AACjD;;AAEA;IACI,iBAAiB;IACjB,6CAAmD;AACvD","sourcesContent":["\r\n@font-face {\r\n    font-family: \"BNR\";\r\n    src: url(\"../../fonts/BebasNeue-Regular.ttf\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"DG\";\r\n    src: url(\"../../fonts/DarkerGrotesque-Regular.ttf\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"NR\";\r\n    src: url(\"../../fonts/NotoSansTelugu_Condensed-Regular.ttf\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"NL\";\r\n    src: url(\"../../fonts/NotoSansTelugu_Condensed-Light.ttf\");\r\n}\r\n@font-face {\r\n    font-family: \"NT\";\r\n    src: url(\"../../fonts/NotoSansTelugu_Condensed-Thin.ttf\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"NSB\";\r\n    src: url(\"../../fonts/NotoSansTelugu_Condensed-SemiBold.ttf\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"ISB\";\r\n    src: url(\"../../fonts/Inter_24pt-SemiBold.ttf\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"IB\";\r\n    src: url(\"../../fonts/Inter_24pt-Bold.ttf\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"IR\";\r\n    src: url(\"../../fonts/Inter_24pt-Regular.ttf\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"II\";\r\n    src: url(\"../../fonts/Inter_24pt-Italic.ttf\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"RR\";\r\n    src: url(\"../../fonts/RobotoCondensed-Regular.ttf\");\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
