import React from 'react';
import LichKabinetHeader from '../components/LichKabinet/LichKabinetHeader';
import MyprofileComponent from '../components/LichKabinet/MyprofileComponent';
import MyAccountsComponent from '../components/LichKabinet/homepage/MyAccounts';
import RecentTransactionsComponent from '../components/LichKabinet/transactions/RecentTransactionsComponent';
import TopUpBalance from '../components/LichKabinet/myaccounts/TopUpBalance';
import ReferalLink from '../components/LichKabinet/ReferalLink';

function MyAccountsPage() {
    return (
        <div className='lich-kabinet'>
            <LichKabinetHeader />
            <ReferalLink />
            <div style={{ display: "flex", gap: "20px" }}>
                <MyprofileComponent />
                <div style={{ display: "flex", flexDirection: "column", gap: "20px", overflow: "hidden" }}>
                    <MyAccountsComponent />
                    <TopUpBalance />
                    <div className='my-accounts-recent-transactions'>
                        <RecentTransactionsComponent />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyAccountsPage;
