import React, { useState, useEffect } from 'react';

import avatar from '../../../images/LichKabinet/settings/avatar.png';
import edit from '../../../images/LichKabinet/settings/edit.png';

const SettingsForm = () => {
    const [activeTab, setActiveTab] = useState('editProfile');
    const [visibleTab, setVisibleTab] = useState('editProfile');
    const [dob, setDob] = useState('1990-01-25');
    const [uploadedFile, setUploadedFile] = useState(null);

    useEffect(() => {
        const timeoutId = setTimeout(() => setVisibleTab(activeTab), 500);
        return () => clearTimeout(timeoutId);
    }, [activeTab]);

    const handleFileChange = (event) => {
        setUploadedFile(event.target.files[0]);
    };

    const handleSave = (event) => {
        event.preventDefault();
        alert('All settings saved!');
    };

    return (
        <div className='settings-form-component'>
            <section className='settings-header-h1'>
                <h1
                    className={activeTab === 'editProfile' ? 'active-tab' : 'inactive-tab'}
                    onClick={() => setActiveTab('editProfile')}
                >
                    Edit Profile
                </h1>
                <h1
                    className={activeTab === 'preferences' ? 'active-tab' : 'inactive-tab'}
                    onClick={() => setActiveTab('preferences')}
                >
                    Preferences
                </h1>
                <h1
                    className={activeTab === 'security' ? 'active-tab' : 'inactive-tab'}
                    onClick={() => setActiveTab('security')}
                >
                    Security
                </h1>
            </section>
            <div className={`transition-wrapper ${visibleTab === activeTab ? 'visible' : ''}`}>
                {visibleTab === 'editProfile' && (
                    <section className='edit-profile-settings'>
                        <div className='profile-avatar'>
                            <img src={avatar} className='avatar-settings' alt='Avatar' />
                            <img src={edit} alt='Edit Icon' className='edit-pen' />
                        </div>

                        <form className='profile-form' onSubmit={handleSave}>

                            {renderInputField('name', 'Your Name', 'text', 'User1')}
                            {renderInputField('username', 'User Name', 'text', 'User1')}
                            {renderInputField('email', 'Email', 'email', 'charlenereed@gmail.com')}
                            {renderInputField('password', 'Password', 'password', '**********')}
                            <div className='form-group'>
                                <label htmlFor='dob'>Date of Birth</label>
                                <input
                                    id='dob'
                                    name='dob'
                                    type='date'
                                    value={dob}
                                    onChange={(e) => setDob(e.target.value)}
                                />
                            </div>
                            {renderInputField('presentAddress', 'Present Address', 'text', 'San Jose, California, USA')}
                            {renderInputField('permanentAddress', 'Permanent Address', 'text', 'San Jose, California, USA')}
                            {renderInputField('city', 'City', 'text', 'San Jose')}
                            {renderInputField('postalCode', 'Postal Code', 'text', '45962')}
                            {renderInputField('country', 'Country', 'text', 'USA')}
                            <div className='form-group'>
                                <label htmlFor='documentUpload'>Upload Document</label>
                                <input
                                    id='documentUpload'
                                    name='documentUpload'
                                    type='file'
                                    onChange={handleFileChange}
                                />
                                <h2 className='settings-form-gray-text'>
                                    If you are unable to upload the required documents in your Personal Account,
                                    you can send them to the email address payments@alpari.im, specifying your
                                    Personal Account number (14043850) in the subject line of the email.
                                </h2>
                            </div>



                            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                <button type='submit' className='button-save-settings'>Save</button>
                            </div>
                        </form>

                    </section>
                )}

                {visibleTab === 'preferences' && (
                    <section className='preferences-settings'>
                        <h2>Coming soon...</h2>
                    </section>
                )}

                {visibleTab === 'security' && (
                    <section className='security-settings'>
                        <h2>Coming soon...</h2>
                    </section>
                )}
            </div>
        </div>
    );
};

const renderInputField = (id, label, type = 'text', placeholder = '') => (
    <div className='form-group'>
        <label htmlFor={id}>{label}</label>
        <input id={id} name={id} type={type} placeholder={placeholder} />
    </div>
);

export default SettingsForm;