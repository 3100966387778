import React from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { publicRoutes } from './routes';
import ScrollToTop from "./components/ScrollToTop";
const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {publicRoutes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
