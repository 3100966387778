import React, { useState } from 'react';
import { Link } from 'react-scroll';

import logo from '../../images/Header/headerlogo.png';
import cross from '../../images/Header/cross.png';
import lines from '../../images/Header/lines.png';
import { useNavigate } from 'react-router-dom';

const HeaderComponent = () => {
    const [overlayVisible, setOverlayVisible] = useState(false);
    const navigate = useNavigate();
    const toggleOverlay = () => {
        setOverlayVisible(!overlayVisible);
        document.body.classList.toggle('no-scroll', !overlayVisible);
    };


    return (
        <main>
            {overlayVisible && (
                <div className='overlay-header' >
                    <img src={cross} onClick={toggleOverlay} className='cross-header' />
                    <section>
                        <Link
                            to="second-comp"
                            smooth={true}
                            duration={500}
                            onClick={toggleOverlay}
                        >
                            <p>Company</p>
                        </Link>
                        <Link
                            to="project"
                            smooth={true}
                            duration={500}
                            onClick={toggleOverlay}
                        >
                            <p>Projects</p>
                        </Link>
                        <Link
                            to="news"
                            smooth={true}
                            duration={500}
                            onClick={toggleOverlay}
                        >
                            <p>News</p>
                        </Link>
                        <Link
                            to="partners"
                            smooth={true}
                            duration={500}
                            onClick={toggleOverlay}
                        >
                            <p>Partners</p>
                        </Link>
                        <Link
                            to="contact"
                            smooth={true}
                            duration={500}
                            onClick={toggleOverlay}
                        >
                            <p>Contact</p>
                        </Link>

                        <button onClick={toggleOverlay}>
                            Invest
                        </button>
                    </section>

                </div>
            )}
            <header className='header-main'>
                <Link
                    to="top"
                    smooth={true}
                    duration={500}
                >
                    <img src={logo} alt="Logo" className='logo-header' />
                </Link>
                <section>
                    <Link
                        to="second-comp"
                        smooth={true}
                        duration={500}
                    >
                        <p>Company</p>
                    </Link>
                    <Link
                        to="project"
                        smooth={true}
                        duration={500}
                    >
                        <p>Projects</p>
                    </Link>
                    <Link
                        to="news"
                        smooth={true}
                        duration={500}
                    >
                        <p>News</p>
                    </Link>
                    <Link
                        to="partners"
                        smooth={true}
                        duration={500}
                    >
                        <p>Partners</p>
                    </Link>
                    <Link
                        to="contact"
                        smooth={true}
                        duration={500}
                    >
                        <p>Contact</p>
                    </Link>
                    <button onClick={() => { navigate("/lk") }}>
                        Invest
                    </button>
                </section>
                <img
                    src={lines}
                    className='lines-header'
                    onClick={toggleOverlay}
                />
            </header>
        </main>
    );
};

export default HeaderComponent;
