import React from 'react';
import { useNavigate } from 'react-router-dom';
import back from '../../images/FirstComponent/FirstComponentBack.gif'
function FirstComponent() {
    const navigate = useNavigate()
    return (
        <div className='first-component-div'>
            <section className='second-f-fc'>
                <h1> Lumina<br />
                    Investment<br />
                    Fund</h1>
                <img src={back} />
            </section>
            <section className='second-s-fc'>
                <h2>Great companies come from the big dreams of determined leaders.</h2>
                <p>We invest in founders with the imagination, tenacity & collaborative spirit needed to turn big ideas into even bigger businesses.</p>
                <section>
                    {/* <button className='second-s-fc-button' onClick={() => { navigate('/lk') }}>Log in</button>
                    <button className='second-s-fc-sbutton' onClick={() => { navigate('/lk') }}>Invest</button> */}

                    <button className='second-s-fc-button' onClick={() => { navigate('/lk') }}>Log in</button>
                    <button className='second-s-fc-sbutton' onClick={() => { navigate('/lk') }}>Invest</button>
                </section>
            </section>
        </div >
    );
}

export default FirstComponent;
