import { Link, useLocation } from "react-router-dom";

import apple from '../../../images/LichKabinet/investmen/apple.png'
import google from '../../../images/LichKabinet/investmen/google.png'
import tesla from '../../../images/LichKabinet/investmen/tesla.png'
const MyinvestmentsComponent = () => {
    const location = useLocation();
    return (
        <main className="my-investments-component">
            <section className="top-statistic-account">
                <h1 className='h1-statistic-account'>My Investment</h1>
                {location.pathname === '/lk' && <Link to='/lk/myinvestments'>See All</Link>}
            </section>

            <div className="investment-div">
                <img src={apple} />

                <section><h2>Apple Store</h2><p>E-commerce, Marketplace</p></section>
                <section><h2>$54,000</h2> <p>Envestment Value</p></section>

                <section> <span className="investment-plus">+16%</span> <p>Return Value</p></section>
            </div>
            <div className="investment-div">
                <img src={google} />

                <section><h2>Samsung Mobile</h2><p>E-commerce, Marketplace</p></section>
                <section><h2>$25,300</h2> <p>Envestment Value</p></section>

                <section> <span className="investment-minus">-8%</span> <p>Return Value</p></section>
            </div>
            <div className="investment-div">
                <img src={tesla} />

                <section><h2>Tesla Motors</h2><p>Electric Vehicles</p></section>
                <section><h2>$8,200</h2> <p>Envestment Value</p></section>

                <section> <span className="investment-minus">-16%</span> <p>Return Value</p></section>
            </div>

        </main>
    );
};

export default MyinvestmentsComponent;
