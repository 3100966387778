import React from 'react';
import { useInView } from 'react-intersection-observer';
import back from '../../images/FourthComponent/back.jpg';
import stat from '../../images/FourthComponent/stat.png';

function FourthComponent() {
    const { ref: h1Ref, inView: h1InView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const { ref: pRef, inView: pInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <div className='fourth-component'>
            <img src={back} alt="Background" className='fourth-back'/>
            <h1 ref={h1Ref} className={h1InView ? 'animate-on-h1' : 'opacity-none'}>
                GREAT COMPANIES COME FROM THE BIG DREAMS OF DETERMINED <br />LEADERS
            </h1>
            <p ref={pRef} className={pInView ? 'animate-on-p' : 'opacity-none'}>
                We invest in founders with the imagination, tenacity & collaborative spirit needed to turn big ideas into even bigger businesses.
            </p>
            <img src={stat} alt="Background" className='foutht-stat'/>

        </div>
    );
}

export default FourthComponent;
