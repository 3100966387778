import React from 'react';

import montly from '../../../images/LichKabinet/investmen/montly.png'

const Monthly = () => {

    return (
        <div className='my-expense-component'>
            <h1 className='h1-statistic-account'>Monthly Revenue</h1>
            <img src={montly} style={{height:"260px"}}/>
        </div>
    );
};

export default Monthly;
