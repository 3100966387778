
import mybalance from '../../../images/LichKabinet/mystatistic/mybalance.png'
import income from '../../../images/LichKabinet/mystatistic/income.png'
import expense from '../../../images/LichKabinet/mystatistic/expense.png'
import total from '../../../images/LichKabinet/mystatistic/total.png'

const MystatisticComponent = () => {

    return (
        <main className='my-statistic-account'>
            <h1 className='h1-statistic-account'>My statistics</h1>
            <div className='my-statistic-account-div-one'>
                <section>
                    <img src={mybalance} />
                    <div>
                        <h2>My Balance</h2>
                        <p>$12,750</p>
                    </div>
                </section>
                <section>
                    <img src={income} />
                    <div>
                        <h2>Income</h2>
                        <p>$5,600</p>
                    </div>
                </section>
            </div>


            <div className='my-statistic-account-div-two'>
                <section>
                    <img src={expense} />
                    <div>
                        <h2>Expense</h2>
                        <p>$3,460</p>
                    </div>
                </section>
                <section>
                    <img src={total} />
                    <div>
                        <h2>Total Saving</h2>
                        <p>$7,920</p>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default MystatisticComponent;
