import React from 'react';

import yearly from '../../../images/LichKabinet/investmen/yearly.png'

const YearlyComponent = () => {

    return (
        <div className='my-expense-component'>
            <h1 className='h1-statistic-account'>Yearly Total Investment</h1>
            <img src={yearly} style={{height:"260px"}}/>
        </div>
    );
};

export default YearlyComponent;
