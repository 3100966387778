import React from 'react';

import planet from '../../../images/LichKabinet/investmen/planet.png'

const SalesMaping = () => {

    return (
        <div className='my-expense-component'>
            <h1 className='h1-statistic-account'>Sales Mapping by Country</h1>
            <img src={planet} style={{height:"260px"}}/>
        </div>
    );
};

export default SalesMaping;
