import statistic from '../../../images/LichKabinet/ExpenseStatistics/expensestatistics.png'

const ExpenseStatistics = () => {
    return (
        <main className='expense-statistic-component'>
            <h1 className='h1-statistic-account'>Expense Statistics</h1>
            <img src={statistic} />
        </main>
    );
};

export default ExpenseStatistics;
