import React from 'react';

import hand from '../../images/projects/hand.png'
import shum from '../../images/shum.png'
import users from '../../images/projects/users.png'
import brokerstats from '../../images/projects/brokerstats.png'
import baterybroker from '../../images/projects/baterybroker.png'
import buttonnews from '../../images/buttonnews.png'
function ProjectsComponent() {
    return (
        <div className='project-component' id='project'>
            <section className='first-project-component'>
                <h1>PROJECTS</h1>
                <p>Our fund offers ongoing projects in which you can invest and receive a monthly income.
                    We invite you to explore our current offerings and choose the project that best aligns with your goals.
                    By investing your funds, you can start receiving stable income from the very first month.</p>
                <div><button>See More Projects</button></div>
            </section>
            <section className='second-project-component'>


                <section className='broker-section'>
                    <div>
                        <img src={brokerstats} />
                        <span>01</span>
                    </div>
                    <h1>Broker</h1>
                    <p>We make concentrated investments to ensure each founder has the capital and support they need to succeed.</p>

                    <img src={baterybroker} className='battery-broker' />
                    <img src={buttonnews} className='button-news' />
                </section>



                <div className='second-project-component-div'>
                    <section className='real-estate-component'>
                        <img src={shum} className='overlay-real-estate' />
                        <h1>REAL ESTATE</h1>
                        <h2>COOMING SOON</h2>
                        <div>
                            <img src={hand} />
                            <p>02</p>
                        </div>
                    </section>
                    <section className='users-component'>
                        <h1>150+</h1>
                        <div>
                            <p>new users join us every week</p>
                            <img src={users} />
                        </div>
                    </section>
                </div>
            </section>
        </div>
    );
}

export default ProjectsComponent;
