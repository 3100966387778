import React from 'react';
import LichKabinetHeader from '../components/LichKabinet/LichKabinetHeader';
import MyprofileComponent from '../components/LichKabinet/MyprofileComponent';
import ReferalLink from '../components/LichKabinet/ReferalLink';
import DepositComponent from '../components/LichKabinet/deposit/DepositComponent';

function DepositProgramPage() {
    return (
        <div className='lich-kabinet'>
            <LichKabinetHeader />
            <ReferalLink />
            <div style={{ display: "flex", gap: "20px" }}>
                <MyprofileComponent />
                <DepositComponent />
            </div>
        </div>
    );
}

export default DepositProgramPage;
