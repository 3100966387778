import React from 'react';

import './css/Fonts.css'
import './css/Animations.css';
import './css/Main.css';
import './css/MainMobile.css';

import HeaderComponent from '../components/Main/HeaderComponent';
import FirstComponent from '../components/Main/FirstComponent';
import SecondComponent from '../components/Main/SecondComponent';
import ThirdComponent from '../components/Main/ThirdComponent';
import FourthComponent from '../components/Main/FourthComponent';
import ParthnersComponent from '../components/Main/ParthnersComponent';
import NewsComponent from '../components/Main/NewsComponent';
import ProjectsComponent from '../components/Main/ProjectsComponent';
import FifthComponent from '../components/Main/FifthComponent';
import FoterComponent from '../components/Main/FoterComponent';
import LinesComponent from '../components/Main/LinesComponent';

function Main() {
    return (
        <div>
            <div id="top"></div>
            <LinesComponent />
            <HeaderComponent />
            <FirstComponent />
            <SecondComponent />
            <ThirdComponent />
            <FourthComponent />
            <FifthComponent />
            <ProjectsComponent />
            <NewsComponent />
            <ParthnersComponent />
            <FoterComponent />
         
        </div>
    );
}

export default Main;
