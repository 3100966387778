import React, { useState } from 'react';
import Select from 'react-select';

const mastercard = require('../../../images/LichKabinet/sendmoney/mastercard.png');
const visa = require('../../../images/LichKabinet/sendmoney/visa.png');
import engfra from '../../../images/LichKabinet/sendmoney/engfranc.png';
import friend from '../../../images/LichKabinet/sendmoney/friend.png';
import plus from '../../../images/LichKabinet/sendmoney/plus.png';

const SendMoney = () => {
    const [paymentMethod, setPaymentMethod] = useState('debit');
    const [amount, setAmount] = useState('');

    const options = [
        {
            value: 'debit', label: (
                <main>
                    <section><img src={mastercard} alt="mastercard" />Debit</section>
                    <section><p>$</p> 10.680</section>
                </main>
            ),
        },
        {
            value: 'visa', label: (
                <main>
                    <section><img src={visa} alt="visa" />Visa</section>
                    <section><p>$</p> 2070</section>
                </main>
            ),
        },
    ];

    const handlePaymentMethodChange = (selectedOption) => {
        setPaymentMethod(selectedOption.value);
    };

    const handleAmountChange = (e) => {
        const value = e.target.value;
        if (value === '' || parseFloat(value) >= 0) {
            setAmount(value);
        }
    };

    return (
        <main className='send-money-component'>
            <h1 className="h1-statistic-account">Send Money</h1>

            <div className="payment-method-selection">
                <Select
                    value={options.find(option => option.value === paymentMethod)}
                    onChange={handlePaymentMethodChange}
                    options={options}
                    isSearchable={false}
                />
            </div>

            <div className='send-money-amount'>
                <h2>Enter the amount</h2>
                <main>
                    <div></div>
                    <section>
                        <span>$</span>
                        <input
                            type="number"
                            min="0"
                            value={amount}
                            onChange={handleAmountChange}
                        />
                    </section>
                    <img src={engfra} alt="example" />
                </main>
            </div>

            <div className='friends-send-money'>
                <div><img src={friend} /> <p>Hanna Soloviova</p></div>

                <img src={plus} />
            </div>

            <button className='send-money-button'>Add to Balance</button>
        </main>
    );
};

export default SendMoney;
