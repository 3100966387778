import React from 'react';

import myexpense from '../../../images/LichKabinet/ExpenseStatistics/myexpense.png';

const MyExpenseComponent = () => {

    return (
        <div className='my-expense-component'>
            <h1 className='h1-statistic-account'>My Expense</h1>
            <img src={myexpense} />
        </div>
    );
};

export default MyExpenseComponent;
