import React from 'react';
import { useInView } from 'react-intersection-observer';

function FifthComponent() {
    const { ref: sectionOneRef, inView: sectionOneInView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    const { ref: sectionTwoRef, inView: sectionTwoInView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    const { ref: sectionThreeRef, inView: sectionThreeInView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    return (
        <div className='fifth-component'>
            <section 
                ref={sectionOneRef} 
                className={`fifth-section-one ${sectionOneInView ? 'animate-on-slidebottom-slow' : 'opacity-none'}`}>
                <h1>LONG-TERM THINKERS</h1>
                <p>We invest across multiple rounds to form committed partnerships with early to growth stage companies.</p>
            </section>
            <section 
                ref={sectionTwoRef} 
                className={`fifth-section-two ${sectionTwoInView ? 'animate-on-slidebottom' : 'opacity-none'}`}>
                <h1>FOCUSED INVESTORS</h1>
                <p>We make concentrated investments to ensure each founder has the capital and support they need to succeed.</p>
            </section>
            <section 
                ref={sectionThreeRef} 
                className={`fifth-section-thr ${sectionThreeInView ? 'animate-on-slidebottom-fast' : 'opacity-none'}`}>
                <h1>SECTOR EXPERTS</h1>
                <p>We have a proven track record and expertise in building successful, technology-led businesses across B2C and B2B sectors.</p>
            </section>
        </div>
    );
}

export default FifthComponent;