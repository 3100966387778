import React from 'react';

import copy from '../../images/LichKabinet/copy.png'

const ReferalLink = () => {

    return (
        <div className='referal-link-component'>
            <p>Моя реферальная ссылка: https://grid-x</p>
            <img src={copy} className='copytrading'/>
        </div>
    );
};

export default ReferalLink;
