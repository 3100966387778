import React from 'react';
import LichKabinetHeader from '../components/LichKabinet/LichKabinetHeader';
import MyprofileComponent from '../components/LichKabinet/MyprofileComponent';
import MyinvestmentsComponent from '../components/LichKabinet/homepage/MyinvestmentsComponent';
import TrendingStock from '../components/LichKabinet/investments/TrendingStock';
import YearlyComponent from '../components/LichKabinet/investments/YearlyComponent';
import Monthly from '../components/LichKabinet/investments/Monthly';
import SalesMaping from '../components/LichKabinet/investments/SalesMaping';
import ReferalLink from '../components/LichKabinet/ReferalLink';




function MyInvestments() {
    return (
        <div className='lich-kabinet'>
            <LichKabinetHeader />
            <ReferalLink />
            <div style={{ display: "flex", gap: "20px" }}>
                <MyprofileComponent />
                <div className='center-my-transactions'>
                    <MyinvestmentsComponent />
                    <TrendingStock />
                    <YearlyComponent />
                    <Monthly />
                    <SalesMaping />
                    <button className='invest-new-project'>Invest new project</button>
                </div>


            </div>


        </div>
    );
}

export default MyInvestments;
