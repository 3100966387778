export const referals = [
    {
        date: '28 Jan, 12.30 AM',
        name: 'Андрей',
        login: 'kerimov1',
        amount: '50,00',
        status: '',
        inst: 1,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Ника',
        login: 'nika123',
        amount: '500,00',
        status: '',
        inst: 1,
        telegram: 0
    },
    {
        date: '25 Jan, 10.40 PM',
        name: 'Екатерина',
        login: 'kate0009',
        amount: '1200,00',
        status: '',
        inst: 0,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Андрей',
        login: 'kerimov1',
        amount: '50,00',
        status: '',
        inst: 1,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Ника',
        login: 'nika123',
        amount: '500,00',
        status: '',
        inst: 1,
        telegram: 0
    },
    {
        date: '25 Jan, 10.40 PM',
        name: 'Екатерина',
        login: 'kate0009',
        amount: '1200,00',
        status: '',
        inst: 0,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Андрей',
        login: 'kerimov1',
        amount: '50,00',
        status: '',
        inst: 1,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Ника',
        login: 'nika123',
        amount: '500,00',
        status: '',
        inst: 1,
        telegram: 0
    },
    {
        date: '25 Jan, 10.40 PM',
        name: 'Екатерина',
        login: 'kate0009',
        amount: '1200,00',
        status: '',
        inst: 0,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Андрей',
        login: 'kerimov1',
        amount: '50,00',
        status: '',
        inst: 1,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Ника',
        login: 'nika123',
        amount: '500,00',
        status: '',
        inst: 1,
        telegram: 0
    },
    {
        date: '25 Jan, 10.40 PM',
        name: 'Екатерина',
        login: 'kate0009',
        amount: '1200,00',
        status: '',
        inst: 0,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Андрей',
        login: 'kerimov1',
        amount: '50,00',
        status: '',
        inst: 1,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Ника',
        login: 'nika123',
        amount: '500,00',
        status: '',
        inst: 1,
        telegram: 0
    },
    {
        date: '25 Jan, 10.40 PM',
        name: 'Екатерина',
        login: 'kate0009',
        amount: '1200,00',
        status: '',
        inst: 0,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Андрей',
        login: 'kerimov1',
        amount: '50,00',
        status: '',
        inst: 1,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Ника',
        login: 'nika123',
        amount: '500,00',
        status: '',
        inst: 1,
        telegram: 0
    },
    {
        date: '25 Jan, 10.40 PM',
        name: 'Екатерина',
        login: 'kate0009',
        amount: '1200,00',
        status: '',
        inst: 0,
        telegram: 1
    },

    {
        date: '28 Jan, 12.30 AM',
        name: 'Андрей',
        login: 'kerimov1',
        amount: '50,00',
        status: '',
        inst: 1,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Ника',
        login: 'nika123',
        amount: '500,00',
        status: '',
        inst: 1,
        telegram: 0
    },
    {
        date: '25 Jan, 10.40 PM',
        name: 'Екатерина',
        login: 'kate0009',
        amount: '1200,00',
        status: '',
        inst: 0,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Андрей',
        login: 'kerimov1',
        amount: '50,00',
        status: '',
        inst: 1,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Ника',
        login: 'nika123',
        amount: '500,00',
        status: '',
        inst: 1,
        telegram: 0
    },
    {
        date: '25 Jan, 10.40 PM',
        name: 'Екатерина',
        login: 'kate0009',
        amount: '1200,00',
        status: '',
        inst: 0,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Андрей',
        login: 'kerimov1',
        amount: '50,00',
        status: '',
        inst: 1,
        telegram: 1
    },
    {
        date: '28 Jan, 12.30 AM',
        name: 'Ника',
        login: 'nika123',
        amount: '500,00',
        status: '',
        inst: 1,
        telegram: 0
    },
    {
        date: '25 Jan, 10.40 PM',
        name: 'Екатерина',
        login: 'kate0009',
        amount: '1200,00',
        status: '',
        inst: 0,
        telegram: 1
    },



]
