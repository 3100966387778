
import historyimg from '../../../images/LichKabinet/history.png'

const BalanceHistoryComponent = () => {

    return (
        <main className="balance-history-component">
            <h1 className="h1-statistic-account">Balance History</h1>

            <div>
                <img src={historyimg}/>
            </div>
        </main>
    );
};

export default BalanceHistoryComponent;
