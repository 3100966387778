import React from 'react';

import checkmark from '../../../images/LichKabinet/checkmark.png'
const DepositComponent = () => {

    return (
        <div className='deposit-component'>
            <h1>Выберите свой идеальный депозитный план</h1>
            <h2> Выберите подходящий депозитный план, который соответствует вашим финансовым целям. Каждый план предлагает различные условия, процентные ставки и срок депозита, чтобы обеспечить вам максимальную выгоду. Независимо от того, ищете ли вы короткосрочные инвестиции или долгосрочные накопления, здесь вы найдёте оптимальное решение.</h2>

            <main>
                <section className='deposit-plan'>
                    <p>Lite</p>
                    <p><b>3</b>/month</p>

                    <p>Идеальный вариант для тех, кто хочет попробовать короткий срок депозита с умеренной доходностью. Проценты можно выводить каждый месяц, а основной депозит доступен через 3 месяца.</p>

                    <button>Select Lite</button>

                    <hr />

                    <div className='deposit-description'>
                        <div><img src={checkmark} />Процентная ставка: 4% ежемесячно</div>
                        <div><img src={checkmark} />Ежемесячный доход: 4% ежемесячно</div>
                        <div><img src={checkmark} /> Вывод депозита: Через 3 месяца</div>
                    </div>
                </section>

                <section className='deposit-plan'>
                    <p>Standard</p>
                    <p><b>6</b>/month</p>

                    <p>Оптимальный выбор для тех, кто хочет стабильный доход на полгода. Проценты можно выводить каждый месяц, а основной депозит доступен через 6 месяцев.</p>

                    <button>Select Lite</button>

                    <hr />

                    <div className='deposit-description'>
                        <div><img src={checkmark} />Процентная ставка: 4% ежемесячно</div>
                        <div><img src={checkmark} />Ежемесячный доход: 4% ежемесячно</div>
                        <div><img src={checkmark} />Вывод депозита: Через 6 месяцев</div>
                    </div>
                </section>
                <section className='deposit-plan'>
                    <p>Recommended</p>
                    <p><b>9</b>/month</p>

                    <p>Рекомендуемый план для тех, кто хочет увеличить доход при более длительном сроке депозита. Проценты можно выводить каждый месяц, а основной депозит доступен через 9 месяцев.</p>

                    <button>Select Lite</button>

                    <hr />

                    <div className='deposit-description'>
                        <div><img src={checkmark} />Процентная ставка: 4% ежемесячно</div>
                        <div><img src={checkmark} />Ежемесячный доход: 4% ежемесячно</div>
                        <div><img src={checkmark} />Вывод депозита: Через 9 месяцев</div>
                    </div>
                </section>
                <section className='deposit-plan'>
                    <p>Pro</p>
                    <p><b>12</b>/month</p>

                    <p>Максимальная доходность для долгосрочных инвесторов. Проценты можно выводить каждый месяц, а основной депозит доступен через 12 месяцев.</p>

                    <button>Select Lite</button>

                    <hr />

                    <div className='deposit-description'>
                        <div><img src={checkmark} />Процентная ставка: 4% ежемесячно</div>
                        <div><img src={checkmark} />Ежемесячный доход: 12% ежемесячно</div>
                        <div><img src={checkmark} />Вывод депозита: Через 12 месяцев</div>
                    </div>
                </section>
            </main>

            <h1> Парнерская прграмма:</h1>
            <h2>Чем больше друзей вы приглашаете в партнерскую программу, тем больше ваш доход!</h2>
        </div>
    );
};

export default DepositComponent;
