import DepositProgramPage from "./pages/DepositProgramPage"
import LichCabinet from "./pages/LichCabinet"
import Main from "./pages/Main"
import MyAccountsPage from "./pages/MyAccounts"
import MyInvestments from "./pages/MyInvestments"
import MyTransaction from "./pages/MyTransaction"
import ReferalProgram from "./pages/ReferalProgram"
import Settings from "./pages/Settings"
import { ACCOUNTS_ROUTE, INVEST_ROUTE, LICH_ROUTE, MAIN_ROUTE, SETTINGS_ROUTE, TRANS_ROUTE,DEPOSIT_ROUTE, REFERAL_ROUTE } from "./utils/consts"


export const publicRoutes = [
    {
        path: MAIN_ROUTE,
        Component: Main

    },

    {
        path: LICH_ROUTE,
        Component: LichCabinet

    },

    {
        path: TRANS_ROUTE,
        Component: MyTransaction

    },
  
    {
        path: INVEST_ROUTE,
        Component: MyInvestments

    },

    {
        path: SETTINGS_ROUTE,
        Component: Settings

    },
    {
        path: ACCOUNTS_ROUTE,
        Component: MyAccountsPage

    },
    {
        path: DEPOSIT_ROUTE,
        Component: DepositProgramPage

    },
    {
        path: REFERAL_ROUTE,
        Component: ReferalProgram

    },

  
]